.APP_CARD_ROOT_w{
    cursor:pointer;
    display:flex;
    flex-direction: column;
    height:120px;
    width:100px;
    border:thin solid transparent;
    border-radius:5px;
    color:#707070;
    margin: 5px;
    background-color: white;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    padding:5px;
    outline:none;
    box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
    font-family: Lato;
}

.APP_CARD_ROOT_SELECTED_w{
    position: relative;
    cursor:pointer;
    display:flex;
    flex-direction: column;
    height:120px;
    width:100px;
    border-radius:5px;
    color:#707070;
    box-shadow: #00000033 2px 2px 0px 0px;
    margin: 5px;
    background-color: #fff5f5;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    border:thin solid #f77f7f;
    padding:5px;
    outline:none;
    font-weight: bold;
    font-family: Lato;

}
.APP_CARD_ROOT_w:hover{
    background-color: #f77f7f33 !important;
    color:#707070!important;
    border:thin solid transparent;
}
.APP_CARD_ROOT_mt{
    cursor:pointer;
    display:flex;
    flex-direction: column;
    height:120px;
    width:100px;
    border:thin solid transparent;
    border-radius:5px;
    color:#707070;
    margin: 5px;
    background-color: white;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    padding:0px;
    outline:none;
    box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
}
.APP_CARD_ROOT_SELECTED_mt{
    cursor:pointer;
    display:flex;
    flex-direction: column;
    height:120px;
    width:100px;
    border-radius:5px;
    color:#707070;
    box-shadow: #00000033 2px 2px 0px 0px;
    margin: 5px;
    background-color: #fff5f5;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    border:thin solid #f77f7f;
    padding:0px;
    outline:none;
    font-weight: bold;
}
.APP_CARD_ICON{
    height:55px;
    max-height:55px;
    min-height:55px;
    width:max-content;
    display: flex;
    place-content: center;
    align-items: center;
    place-self: center;
}
.APP_CARD_CONTENT{
    height:60px;
    max-height:60px;
    min-height:60px;
    width:100%;
    display:flex;
    place-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.4px;
    user-select: none;
}
.APP_CARD_TOPBAR_SELECTED{
    height: 5px;
    width: 100%;
    background-color: rgb(223, 70, 70);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    left: 0px;
    top: 0px;
    position: absolute;
}
.APP_CARD_TOPBAR_NONSELECTED{
    height: 5px;
}