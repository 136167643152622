.existingImg{
    object-fit: cover;
    width: 100%;
    height: auto;
}
.editprojectcontent_displaymsgroot{
    padding:0px;
    display:flex;
    flex-direction: column;
}
.editprojectcontent_displaymsg{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: center;
}

.editprojectcontent_save{
    display:flex;
    border:thin solid #707070;
    padding:5px;
    border-radius: 5px;
    margin-left: 5px;
    background-color: white;
    justify-content: flex-end;
    align-self: flex-end;
    font-weight: bold;
}

.editprojectcontent_view{
    display:flex;
    border:thin solid #707070;
    padding:5px;
    border-radius: 5px;
    margin-left: 5px;
    background-color: white;
    justify-content: flex-end;
    align-self: flex-end;
    font-weight: bold;
}

.editprojectcontent_return{
    display:flex;
    border:thin solid #707070;
    padding:5px;
    border-radius: 5px;
    background-color: white;
}

.editprojectcontent_txt{
    padding:1px 0px 1px 0px;
}
.editprojectcontent_txt:hover{
 text-decoration: none;
 color: #707070;
}

.editprojectcontent_returntxt{
    padding:1px 0px 1px 5px;
}