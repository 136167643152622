.Ad_root{
    display:flex;
    flex-direction: row;
    
}
.ad_gridWEB{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, max-content));
    width: 100%;
    padding: 0px;
    background-color: #ffe7e7;
}
.ad_gridTABLET{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, max-content));
    width: 100%;
    padding: 0px;
    background-color: #ffe7e7;

}
.ad_gridMOBILE{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, max-content));
    width: 100%;
    padding: 0px;
    background-color: #ffe7e7;
}

.ad_cardrootWEB{
    display:flex;
    min-width:350px; 
    max-width:350px; 
    border: thin solid #f77e7e;
    margin:10px;
    border-radius:10px;
    place-content: center;
    align-items: center;
    place-self: center;
    font-family: monospace;
    flex-direction: column;
    color:#707070;
    text-decoration: none;
    background-color: white;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 5px 0px;
}
.ad_cardrootTABLET{
    display:flex;
    width:280px; 
    border: thin solid #f77e7e;
    margin:5px;
    border-radius:10px;
    place-content: center;
    align-items: center;
    place-self: center;
    font-family: monospace;
    flex-direction: column;
    background-color: white;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 5px 0px;
}

.ad_cardrootMOBILE{
    display:flex;
    width:280px; 
    border: thin solid #f77e7e;
    margin:5px;
    border-radius:10px;
    place-content: center;
    align-items: center;
    place-self: center;
    font-family: monospace;
    flex-direction: column;
    background-color: white;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 5px 0px;
}




.Ad_topWEB{
    width:100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color:#707070;
}
.Ad_topTABLET{
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color:#707070;
}
.Ad_topMOBILE{
    width:100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color:#707070;
}

/* left */
.Ad_left{
    height:100%;
    width:60%;
    border-radius:10px;
}

.Ad_leftimg{
    height: calc( 100% - 10px);
    width: calc( 100% - 10px);
    margin: 5px;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
    background-position: center center;
}

/* right */
.Ad_right{
    height:100%;
    width: 40%;
    border-radius:10px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.Ad_rightname{
    font-weight: bold;
    width: 100%;
    min-height: 40%;
    text-align: center;
    align-items: center;
    display: flex;
    place-content: center;
    font-size:16px;
    flex-wrap:wrap;
    white-space: pre-wrap;
    padding: 0px 10px;
}
.Ad_rightprice{
    width: calc( 100% - 10px);
    min-height: 20%;
    font-size: 24px;
    text-align: center;
    align-items: center;
    display: flex;
    place-content: center;
}
.Ad_rightdescrip{
    width: calc( 100% - 10px);
    min-height: 20%;
    overflow: hidden;
    font-size: 10px;
    text-align: center;
}
.Ad_rightbook{
    width: calc( 100% - 5px);
    height: calc( 20% - 10px);
    min-height: calc( 20% - 10px);
    max-height:  calc( 20% - 10px);
    text-align: center;
    align-items: center;
    display: flex;
    place-content: center;
    background-color: #d82a56cf;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    font-size: 14px;
    margin: 5px 0px;
    text-decoration: none;
}