.createprojectcontent_displaymsg{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: center;
}

.createprojectcontent_save{
    display:flex;
    border:thin solid #707070;
    padding:5px;
    border-radius: 5px;
    margin-left: 5px;
    background-color: white;
    justify-content: flex-end;
    align-self: flex-end;
    font-weight: bold;
}

.createprojectcontent_return{
    display:flex;
    border:thin solid #707070;
    padding:5px;
    border-radius: 5px;
    background-color: white;
}

.createprojectcontent_savetxt{
    padding:1px 0px 1px 0px;
}

.createprojectcontent_returntxt{
    padding:1px 0px 1px 5px;
}

.cprojcontent_modalimgsection{
    display: flex;
    width: 105px;
    height: 190px;
    flex-direction: column;
    align-items: center;
    padding: 5px 0px;
    justify-content: space-between;
}

.cprojcontent_modalallsection{
    display: flex;
    width: 105px;
    height: 250px;
    flex-direction: column;
    align-items: center;
    padding: 5px 0px;
    justify-content: space-between;
    cursor: pointer;
}

.cprojcontent_modalbtn{
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
    width: 90px;
    height: 50px;
    margin: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.cprojcontent_inputtxt{
    display: flex;
    width: max-content;
    height: max-content;
    text-align: center;
}

.cprojcontent_addanddelbtn{
    padding: 0px;
    border-radius: 25px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
    min-width: 25px;
    min-height: 25px;
    margin: 5px;
    display: flex;
    justify-content: center;
    place-self: center;
    font-weight: bold;
    align-items: center;
}

.cprojcontent_singlerow{
    display:flex;
    flex-direction: row;
}

