.twoimageinput_root{
    display:flex;
    flex-direction: row;
}
.twoportrait_imginputholder{
    display:flex;
    position:relative;
    width: 150px;
    height: 200px;
}
.twoportrait_img{
    display:flex;
    width: 150px;
    height: 200px;
    position:absolute;
}
.twoportrait_input{
    opacity:0;
    border:'none';
    width:100%;
    height:100%;
}
