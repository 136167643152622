.Vendorlevel_root{
    overflow: auto hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0px;
    border: thin solid #d9d8d8;
    align-items: center;
    border-radius: 5px;
}
.Vendorlevel_subroot{
    padding: 40px 5px 5px;
    height: 100%;
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
}
.Vendorlevel_col{
    display: flex;
    flex-direction: column;
    width: 100%;
}   
.Vendorlevel_row{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Vendorlevel_input{
    display: flex;
    padding:5px;
    margin:5px 5px 5px 10px;
    border-radius: 5px;
    border: thin solid #707070;
}
.Vendorlevel_btn {
    display: flex;
    padding:5px;
    margin:5px 5px 5px 0px;
    border-radius: 5px;
    outline:none;
    background-color: white;
    border: thin solid #707070;
    color: #707070;
}
.Vendorlevel_txt{
    display: flex;
    flex-direction: row;
    color: black;
    text-align: center;
    padding:5px;
    justify-content: center;
    flex-wrap: wrap;
}