.Vptopbar_root{
    flex-direction: row;
    display: flex;
    justify-content: center;
    overflow-x: auto;
    touch-action: manipulation;
    margin-bottom: 30px;
    box-shadow: rgb(32 33 36 / 28%) 0px 1px 6px 0px;
    border-radius: 7px;
    padding: 5px 0px;
    color:var(--maindark);
}
.Vptopbar_false{
    width: 75px;
    height: 65px;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    outline: none;
    border: none;
    background-color: white;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.Vptopbar_true{
    width: 75px;
    height: 65px;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    outline: none;
    border: none;
    background-color:var(--maindark);
    color: white;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-flex-align: center;
    align-items: center;
}
.Vptopbar_false:hover{
    width: 75px;
    height: 65px;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    outline: none;
    border:none;
    background-color:#EFEFEF;
    color:var(--maindark);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-flex-align: center;
    align-items: center;
}
