.editprojectcontent_displaymsg {
    display: flex;
    flex-direction: column;
}

.vp_ew_notselected {
    width: 33%;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin: 5px;
    border: thin solid #D9D8D8;
    border-radius: 5px;
    outline: none;
    color: #B5B5B5;
    font-weight: bold;
    background-color: white;
}

.vp_ew_selected {
    width: 33%;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin: 5px;
    border: thin solid #D9D8D8;
    border-radius: 5px;
    outline: none;
    background-color: rgb(239, 239, 239);
    color: #555555;
    font-weight: bold;
}

.vp_root {
    color: #707070;
    touch-action: manipulation;
}

.vp_sectionroot {
    border: thin solid black;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
}

.vp_titlerow {
    min-height: 50px;
    border: thin solid rgb(217, 216, 216);
    border-radius: 5px;
    padding: 0px 10px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.vp_content {
    display: flex;
    flex-direction: row;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
}

.vp_closedivroot {
    display: flex;
    flex-direction: row;
    font-size: large;
    font-weight: bold;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: red;
    cursor: pointer;
}

.vp_generalroot {
    color: #707070;
}

.vp_glogo {
    border: thin solid rgb(217, 216, 216);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    height: 380px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.vp_profileimgtitle {
    display: flex;
    flex-direction: row;
    font-size: large;
    font-weight: bold;
    margin-bottom: 20px;
}

.vp_emailsroot {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.vp_emailstitle {
    font-size: 14px;
}

.vp_emailssubtitle {
    color: var(--subdark);
    font-size: 12px;
}

.vp_emailmaincol {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 15px;
    border: 1px solid var(--maindark);
    margin-bottom: 10px;
    background-color: #f9fafa;
}

.vp_emailcol {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.vp_emailrow {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 15px;

}

.vp_emailinputtop {
    border: 1px solid var(--maindark);
    border-radius: 10px 0px 0px 0px;
    padding: 10px;
    outline: none;
    border-bottom: none;
    height: 40px;
}

.vp_emailinputbtm {
    border: 1px solid var(--maindark);
    border-radius: 0px 0px 0px 10px;
    padding: 10px;
    outline: none;
    height: 40px;
}

.vp_emailtrash {
    height: 80px;
    ;
    border-radius: 0px 10px 10px 0px;
    color: var(--maindark);
    background-color: #ffffff;
    border: 1px solid var(--maindark);
    border-left: none;
}

.vp_emailadd {
    display: flex;
    padding: 10px;
    background-color: var(--maindark);
    color: #ffffff;
    border-radius: 10px;
    border: 1px solid var(--maindark);
    text-align: center;
    align-items: center;
    place-content: center;
    width: 125px;
    place-self: center;
    outline: none;
    white-space: pre;
}


.vp_btnrow {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

.vp_btnon {
    display: flex;
    padding: 10px;
    margin: 0px;
    width: max-content;
    background-color: white;
    outline: none;
    border: 1px solid var(--maindark);
    border-radius: 5px 0px 0px 5px;
    border-right: none;
}

.vp_btnoff {
    display: flex;
    padding: 10px;
    margin: 0px;
    width: max-content;
    background-color: white;
    outline: none;
    border: 1px solid var(--maindark);
    border-radius: 0px 5px 5px 0px;
    border-left: none;
}

.vp_btnonselected {
    background-color: var(--donegreen);
    color: white;
}

.vp_btnoffselected {
    background-color: var(--mainpink);
    color: white;
}