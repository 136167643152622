/* pattern 1 */
.existprojects_cardpat0WEB{
    width:220px;
    height:330px;
}

.existprojects_cardpat0TABLET{
    width:220px;
    height:330px;
}

.existprojects_cardpat0MOBILE{
    width:170px;
    height:330px;
}

/* pattern 2 */
.existprojects_cardpat1WEB{
    width:220px;
    height:330px;
}

.existprojects_cardpat1TABLET{
    width:170px;
    height:275px;
}

.existprojects_cardpat1MOBILE{
    width:220px;
    height:330px;
}

/* pattern 2 */
.existprojects_cardpat2WEB{
    width:220px;
    height:330px;
}

.existprojects_cardpat2TABLET{
    width:170px;
    height:275px;
}

.existprojects_cardpat2MOBILE{
    width:220px;
    height:330px;
}



