.Vl_rootWEB {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 35px);
    width: 100%;
    touch-action: manipulation;
    flex-direction: row;
}

.VL_LrootWT {
    width: calc(100% - 300px);
    height: 100%;
    min-height: calc(100vh - 35px);
    background-size: cover;
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    background-position: center;
}

.VL_LrootMOBILE {
    width: 100%;
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    padding: 0px 50px 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: calc(100vh - 35px);
}

.VL_Ltitle {
    font-weight: bold;
    font-size: 16px;
    width: 250px;
    color: white;
    padding: 5px 0px;
}

.VL_RrootWEB {
    width: 400px;
    height: 100%;
    display: flex;
    place-content: center;
    align-items: center;
    border-left: 1px solid #eaeaea;
}

.VL_RrootTABLET {
    width: 400px;
    height: 100%;
    display: flex;
    place-content: center;
    align-items: center;
    border-left: 1px solid #eaeaea;
}

.VL_RrootMOBILE {
    width: 100%;
    height: 100vh;
    display: flex;
    place-content: center;
    align-items: center;
    border-left: 1px solid #eaeaea;
}

.VL_feature {
    display: flex;
    flex-direction: row;
    background: #fff;
    -webkit-box-align: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #eaeaea;
    color: var(--maindark);
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(25, 25, 25, 0.15);
    cursor: pointer;
    -webkit-box-pack: center;
    margin: 1px 5px;
    padding: 10px 14px 10px 5px;
    -webkit-transition: color 0.1s linear;
    transition: color 0.1s linear;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 250px;
    text-align: center;
    font-family: monospace;
    /* border: 1px solid var(--maindark); */
}

.VL_featureicon {
    padding: 0px 10px;
    justify-content: flex-start;
}

.VL_featuretitle {
    width: 100%;
    place-content: center;
    color: black;
    font-weight: bold;
}

.Vl_rootTABLET {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 35px);
    width: 100%;
    touch-action: manipulation;
    flex-direction: row;
}

.Vl_rootMOBILE {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 35px);
    width: 100%;
    touch-action: manipulation;
    flex-direction: column;
}

.Vl_innerroot {
    height: 600px;
    width: max-content;
    max-width: 500px;
    padding: 50px 0px;
    background-color: transparent;
    flex-direction: column;
    place-content: center;
    border-radius: 0px 0px 10px;
    display: flex;
    align-items: center;
}

.VL_errorroot {
    display: flex;
    justify-content: center;
    padding-top: 7px;
    height: 30px;
    color: #969696;
}

.VL_linkroot {
    height: 50px;
    color: #3897f0;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    text-decoration: none;
}

.VL_arrowhintroot {
    bottom: 0px;
    height: 88px;
    left: 50%;
    margin-left: -12px;
    overflow: hidden;
    position: absolute;
    width: 24px;
}

.Vl_innerroottop {
    height: 250px;
    width: 300px;
}

.VL_a1 {
    color: white;
    -webkit-animation: arrow1 3s infinite ease-out;
    -moz-animation: arrow1 3s infinite ease-out;
    -o-animation: arrow1 3s infinite ease-out;
    animation: arrow1 3s infinite ease-out;
    opacity: 0;

}

.VL_a2 {
    color: white;
    -webkit-animation: arrow2 3s infinite ease-in;
    -moz-animation: arrow2 3s infinite ease-in;
    -o-animation: arrow2 3s infinite ease-in;
    animation: arrow2 3s infinite ease-in;
    opacity: 1;

}

@-webkit-keyframes arrow1 {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }

    100% {
        opacity: 0;
        transform: translateY(50px);
    }
}

@-webkit-keyframes arrow1 {
    0% {
        opacity: 0;
        transform: translateY(0px);
    }

    100% {
        opacity: 1;
        transform: translateY(50px);
    }
}

.VL_loginbtn{
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--maindark);
    background-color: white;
    color: var(--maindark);
    min-height: 42px;
    height: 42px;
    width: 100%;
    margin-top: 10px;
    place-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    display: flex;
}

.VL_error{
    padding: 10px;
    border-radius: 5px;
    border: medium;
    background-color: white;
    color: var(--maindark);
    min-height: 42px;
    height: 42px;
    margin-top: 10px;
    width: 100%;
    text-align: center;
}
/* @-webkit-keyframes arrow2 {
    0% {
        opacity: 0;
        transform:translateY(100px);
    }
    5%, 43.3554%{
        opacity: 1;
        transform:translateY(0px);
    }
    45.0331% ,100% {
        opacity: 0;
        transform:translateY(-100px);
    }
} */