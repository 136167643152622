.tf_root{
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #707070;
    margin-top: 8px;
}
.tf_input{
    height: 42px;
    box-shadow: none;
    border:thin solid #bababa;
    border-radius: 5px;
    outline: none;
    padding: 0px 5px;
    font-family: inherit;
    background: rgba(var(--b3f,250,250,250),1);
    box-sizing: content-box;
}
@media (min-width:320px)  { 
    .tf_fs{
        font-size: 14px;
    }
}
@media (min-width:481px)  { 
    .tf_fs{
        font-size: 14px;
    }
}
@media (min-width:641px)  {
    .tf_fs{
        font-size: 16px;
    }
}
@media (min-width:961px)  {
    .tf_fs{
        font-size: 16px;
    }
}
@media (min-width:1025px) { 
    .tf_fs{
        font-size: 16px;
    }
}