.Progressbar_root{
    display: flex;
    flex-direction: row;
    width:100%;
    border-radius: 25px;
    height: 35px;
    background-color: white;
    position:relative;
    border:thin solid #707070;
}
.Progressbar_min{
    background-color: #6dc761;
    border-radius: 25px;
    height: 33px;
    border: thick solid white;
    display:flex;
    color: white;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    letter-spacing: 1px;
}