.Pricelistcreation_tagroot {
    display: flex;
    flex-direction: column;
    place-content: flex-start;
    color: rgb(217, 216, 216);
    flex-wrap: wrap;
    color: rgb(85, 85, 85);
}

.Pricelistcreation_tagunselected {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: thin solid rgb(217, 216, 216);
    padding: 10px;
    margin: 5px;
    outline: none;
    font-weight: bold;
    height: 40px;
    white-space: nowrap;
    background-color: white;
    color: rgb(181, 181, 181);
}


.Pricelistcreation_tagselected {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: thin solid rgb(217, 216, 216);
    padding: 10px;
    margin: 5px;
    outline: none;
    font-weight: bold;
    height: 40px;
    white-space: nowrap;
    background-color: rgb(239, 239, 239);
    color: rgb(85, 85, 85);
}


.Pricelistcreation_col {
    display: flex;
    flex-direction: column;
}

.Pricelistcreation_rowwrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}