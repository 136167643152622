.pricingmodel_root{
    display:flex;
    flex-direction: column;
    min-height: calc( 100vh - 35px );
    color: black;
    touch-action: manipulation;
    justify-content: center;
    /* background-color: #f6f9fc; */
    background-color: white;
    /* background-color: #f489a1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23fdf4f6' fill-opacity='0.42'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); */
   
    /* background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' %3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(360,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23fff7fe'/%3E%3Cstop offset='1' stop-color='%23ffdee3'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpattern id='b' width='100' height='100' patternUnits='userSpaceOnUse'%3E%3Ccircle fill='%23ffffff' cx='50' cy='50' r='50'/%3E%3C/pattern%3E%3Crect width='100%25' height='100%25' fill='url(%23a)'/%3E%3Crect width='100%25' height='100%25' fill='url(%23b)' fill-opacity='0.2'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover; */
    background-color: #fef5f6;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='%23ff7988' fill-opacity='0.1'%3E%3Cpolygon fill-rule='evenodd' points='8 4 12 6 8 8 6 12 4 8 0 6 4 4 6 0 8 4'/%3E%3C/g%3E%3C/svg%3E");

}
.pricingmodel_herotitle{
    text-align: center;
    font-size: 60px;
    line-height: 135%;
    margin-bottom: 0.25em;
    z-index:1;
}

.pricingmodel_heropricingtitleMOBILE{
    font-size: 40px;
    line-height: 135%;
    margin-bottom: 0.25em;
    padding: 0 1em;
    margin: 10px auto;
    max-width: 750px;
    color: black;
    font-weight: 600;
    line-height: 125%;
    text-align: center;
    font-family: monospace;
}
.pricingmodel_heropricingtitleTABLET{
    font-size: 48px;
    padding: 0 1em;
    margin: 10px auto;
    max-width: 750px;
    color: black;
    font-weight: 600;
    line-height: 125%;
    text-align: center;
    font-family: monospace;
}

.pricingmodel_heropricingtitleWEB{
    padding: 0 1em;
    margin: 10px auto;
    max-width: 750px;
    color: black;
    font-weight: 600;
    font-size: 55px;
    line-height: 125%;
    text-align: center;
    font-family: monospace;

}
@media (min-width:250px){ 
    .pricingmodel_card{
        width:90%;
        height:550px;
        margin:10px 0px;
        display:flex;
        flex-direction:column;
        border-radius:15px;
        justify-content:space-between;
        align-items:center;
        z-index:1;
    }
    .pricingmodel_subroot{
        justify-content:center;
        padding: '';
        width:100%;
        flex-direction: column;
        margin:0 auto;
        display:flex;
        align-items: center;
    }
}
@media (min-width:350px){ 
    .pricingmodel_card{
        width:80%;
        height:550px;
        margin:10px 0px;
        display:flex;
        flex-direction:column;
        border-radius:15px;
        justify-content:space-between;
        align-items:center;
        z-index:1;

    }
    .pricingmodel_subroot{
        justify-content:center;
        padding: '';
        width:100%;
        flex-direction: column;
        margin:0 auto;
        display:flex;
        align-items: center;
    }
}
@media (min-width:481px){ 
    .pricingmodel_card{
        width:80%;
        height:550px;
        margin:10px 0px;
        display:flex;
        flex-direction:column;
        border-radius:15px;
        justify-content:space-between;
        align-items:center;
        z-index:1;

    }
    .pricingmodel_subroot{
        justify-content:center;
        padding: 50px 16px 16px;
        width:'';
        flex-direction: column;
        margin:0 auto;
        display:flex;
        align-items: center;
    }
}
@media (min-width:641px){
    .pricingmodel_card{
        width:300px;
        height:550px;
        margin:0px 20px;
        display:flex;
        flex-direction:column;
        border-radius:15px;
        justify-content:space-between;
        align-items:center;
        z-index:1;

    }
    .pricingmodel_subroot{
        justify-content:center;
        padding: 50px 16px 16px;
        width:'';
        flex-direction: row;
        margin:0 auto;
        display:flex;
        align-items: center;
    }
}
@media (min-width:961px){
    .pricingmodel_card{
        width:300px;
        height:550px;
        margin:0px 20px;
        display:flex;
        flex-direction:column;
        border-radius:15px;
        justify-content:space-between;
        align-items:center;
        z-index:1;

    }
    .pricingmodel_subroot{
        justify-content:center;
        padding: 70px 80px 80px;
        width:'';
        flex-direction: row;
        margin:0 auto;
        display:flex;
        align-items: center;
    }
}
@media (min-width:1025px){
    .pricingmodel_card{
        width:300px;
        height:550px;
        margin:0px 20px;
        display:flex;
        flex-direction:column;
        border-radius:15px;
        justify-content:space-between;
        align-items:center;
        z-index:1;

    }
    .pricingmodel_subroot{
        justify-content:center;
        padding: 70px 80px 80px;
        width:'';
        flex-direction: row;
        margin:0 auto;
        display:flex;
        align-items: center;
    }
}
@media (min-width:1281px){ 
    .pricingmodel_card{
        width:300px;
        height:550px;
        margin:0px 20px;
        display:flex;
        flex-direction:column;
        border-radius:15px;
        justify-content:space-between;
        align-items:center;
        z-index:1;

    }
    .pricingmodel_subroot{
        justify-content:center;
        padding: 70px 80px 80px;
        width:'';
        flex-direction: row;
        margin:0 auto;
        display:flex;
        align-items: center;
    }
}

.pricingmodel_cardroot{
    display:flex;
    flex-direction: column;
    padding:15px 0px 10px 0px;
}

.pricingmodel_cardtitle{
    display:flex;
    justify-content: center;
    width:100%;
    align-items: center;
    font-weight: bold;
    color:black;
    font-family: monospace;
}
.pricingmodel_cardsubtitle{
    text-align: center;
    font-size: 0.8em;
    font-weight: 600;
    padding: 4px 7px 2px 7px;
    border-radius: 4px;
    color: #fff;
    background-color: #ef3340;
    width: max-content;
    place-self: center;
}

.pricingmodel_cardsubtitleempty{
    text-align: center;
    font-size: 0.8em;
    font-weight: 600;
    padding: 4px 7px 2px 7px;
    border-radius: 4px;
    color: transparent;
    background-color: transparent;
    width: max-content;
    place-self: center;
}

.pricingmodel_cardamtfeaturesroot{
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.pricingmodel_carditem{
    padding:  5px;
    line-height: 170%;
    font-size: 14px;
}

.pricingmodel_redirectbtn{
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.pricingmodel_dollarmoneyroot{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 15px 0px;
    width: 100%;
    place-content: center;
    border-bottom: 1px solid #707070;
    margin-bottom: 15px;
}
.pricingmodel_dollarsign{
    font-size: 16px;
    font-weight:bold;
}
.pricingmodel_dollaramt{
    font-size: 45px;
    display: flex;
    place-self: flex-end;
    padding: 0px 10px;
    font-weight: bold;
}
.pricingmodel_symbol{
    font-size: 16px;
    display: flex;
    place-self: flex-end;
    font-weight:bold;
}
.pricingmodel_subtitleWEB{
    font-size: 14px;
    font-family: monospace;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    padding: 5px;
    background-color: white;
    margin: 25px;
    place-content: center;
    display: flex;
    flex-direction: column;
    width: 420px;
    place-self: center;
}
.pricingmodel_subtitleTABLET{
    font-size: 14px;
    font-family: monospace;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    padding: 5px;
    background-color: white;
    margin: 25px;
    place-content: center;
    display: flex;
    flex-direction: column;
    width: 420px;
    place-self: center;
}
.pricingmodel_subtitleMOBILE{
    font-size: 14px;
    font-family: monospace;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    padding: 5px;
    background-color: white;
    margin: 25px;
    place-content: center;
    display: flex;
    flex-direction: column;
}