.Vendorsignup_signupbtn{
    padding: 10px;
    border-radius: 5px;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    color: black;
    min-height: 42px;
    height: 42px;
    width: 100%;
    margin-top: 10px;
    place-content: center;
    align-items: center;
    text-align: center;
}