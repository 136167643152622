.Vtrend_root{
    flex-direction: column;
    color:#707070;
}
.Vtrend_singletrend{
    max-width: 170px;
    min-width: 170px;
    padding:3px;
    margin:5px 5px 10px 5px;
    overflow-y:auto;
    display:flex;
    flex-direction: column;
    border:thin solid #d8d8d9;
    border-radius:10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
}
.Vtrend_singletrenddate{
    width:100%;
    border-bottom:thin solid #707070;
    background-color:#707070;
    color: white;
    justify-content: center;
    border-radius: 5px;
    padding:5px;
    display:flex;
}
.Vtrend_singletrendcommon{
    width:160px;
    display:flex;
    padding:5px;
    flex-direction: column;
}
.Vtrend_qnansroot{
    display:flex;
    width:150px;
    flex-direction: row;
    flex-wrap: wrap;
}
.Vtrend_ans{
    padding:5px;
    margin:5px;
    border:thin solid #707070;
    width:max-content;
    height:max-content;
    border-radius: 5px;
}

.Dtfilter_root{
    display: flex;
    flex-direction: column;
    padding: 20px 0px 0px 0px;
}

.Dtfilter_subroot{
    display: flex;
    flex-direction: row;
    place-content: center;
    font-family: monospace;
}
.Dtfilteryrtrue{
    padding:10px;
    margin: 2px;
    border-radius:5px;
    cursor: pointer;
    font-weight: bold;
    min-width: 52px;
    width: 33.33%;
    border:thin solid transparent;
    color: white;
    background-color: #eb5d7b;
}
.Dtfilteryrfalse{
    padding:10px;
    margin: 2px;
    border-radius:5px;
    border:thin solid #707070;
    cursor: pointer;
    min-width: 52px;
    width: 33.33%;
}
.Dtfiltertrue{
    padding:10px;
    margin: 2px;
    border-radius:5px;
    cursor: pointer;
    font-weight: bold;
    min-width: 52px;
    border:thin solid transparent;
    color: white;
    background-color: #eb5d7b;
}
.Dtfilterfalse{
    padding:10px;
    margin: 2px;
    border-radius:5px;
    border:thin solid #707070;
    cursor: pointer;
    min-width: 52px;
}
.Dtfiltertitle{
    padding: 0px 5px;
    font-family: monospace;
    font-weight: bold;
}
.Dtfiltercontentroot{
    overflow: auto hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0px;
    border: thin solid #d9d8d8;
    align-items: center;
    border-radius: 5px;
}
.Dtfilterwcroot{
    margin: 20px 0px;
    border:1px solid #eceff1;
    box-shadow: rgb(17 51 83 / 2%) 0px 4px 12px 0px;
}
.vendortrend_btn{
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
}
.vendortrend_sourceroot{
    display:flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    color: #707070;
    width: max-content;
    border: thin solid #707070;
    border-radius:5px;
    margin: 0px 10px;
    place-self: flex-start;
}
.vendortrend_sourcetitle{
    font-family: monospace;
    font-weight: bold;
    border-bottom: thin solid black;
    margin-bottom: 10px;
}
.vendortrend_sourcerow{
    display:flex;
    flex-direction: row;
}
.vendortrend_sourceicon{
    padding:0px 10px 0px 0px;
    color: #707070;;
}
.vendortrend_titleroot{
    font-family: monospace;
}
.vendortrend_itemWEB{
    display:flex;
    flex-direction: row;
    width: 800px;
    border:1px solid rgb(236, 239, 241);
    box-shadow: rgb(17 51 83 / 2%) 0px 4px 12px 0px;
    height: 50px;
    font-family: monospace;
}
.vendortrend_itemTABLET{
    display:flex;
    flex-direction: row;
    width: 500px;
    border:1px solid rgb(236, 239, 241);
    box-shadow: rgb(17 51 83 / 2%) 0px 4px 12px 0px;
    height: 50px;
    font-family: monospace;
}
.vendortrend_itemMOBILE{
    display:flex;
    flex-direction: row;
    width: 230px;
    border:1px solid rgb(236, 239, 241);
    box-shadow: rgb(17 51 83 / 2%) 0px 4px 12px 0px;
    height: 50px;
    font-family: monospace;
}
.vendortrend_text{
    display: flex;
    width: 70%;
    place-content: center;
    align-items: center;
}
.vendortrend_value{
    display: flex;
    width: 30%;
    place-content: center;
    align-items: center;
}
.vendortrend_texttitle{
    display: flex;
    width: 70%;
    place-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
}
.vendortrend_valuetitle{
    display: flex;
    width: 30%;
    place-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
}
.vendortrend_topfewroot{
    display:flex;
    flex-direction: column;
    margin:0px 0px 50px 0px;
}
.vendortrend_btnrootWEB{
    display:flex;
    width:800px;
    flex-direction:row;
    margin-bottom: 5px;
}
.vendortrend_btnrootTABLET{
    display:flex;
    width:500px;
    flex-direction:row;
    margin-bottom: 5px;
}
.vendortrend_btnrootMOBILE{
    display:flex;
    width:230px;
    flex-direction:row;
    margin-bottom: 5px;
}
.vendortrend_btntop{
    display: flex;
    padding: 5px;
    width: 33.33%;
    background-color: white;
    margin: 0px 5px;
    border: thin solid #707070;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    outline:none;
}
.vendortrend_btntop_selected{
    display: flex;
    padding: 5px;
    width: 33.33%;
    background-color: white;
    margin: 0px 5px;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    border : thin solid #eb5d7b;
    color:#eb5d7b;
    outline:none;
    font-weight: bold;
}