.Vlead_root{
    flex-direction: column;
    color:#707070;
    display: flex;
}
.Vlead_subsubroot{
    display:grid;
    grid-template-columns:repeat(auto-fit, minmax(175px, max-content))
}
.Vlead_singlelead{
    max-width: 170px;
    min-width: 170px;
    padding:3px;
    margin:5px 5px 10px 5px;
    overflow-y:auto;
    display:flex;
    flex-direction: column;
    border:thin solid #d8d8d9;
    border-radius:10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
}
.Vlead_singleleaddate{
    width:100%;
    border-bottom:thin solid #707070;
    background-color:#707070;
    color: white;
    justify-content: center;
    border-radius: 5px;
    padding:5px;
    display:flex;
}
.Vlead_singleleadcommon{
    width:160px;
    display:flex;
    padding:5px;
    flex-direction: column;
}
.Vlead_qnansroot{
    display:flex;
    width:150px;
    flex-direction: row;
    flex-wrap: wrap;
}
.Vlead_ans{
    padding:5px;
    margin:5px;
    border:thin solid #707070;
    width:max-content;
    height:max-content;
    border-radius: 5px;
}