
/* WEB */
/* .Jfmodal_web {
    text-align: center;
    padding: 0!important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
  
}
.Jfmodal_web:before {
    content: '';
    overflow-y: hidden;
    align-items: center;
}
.Jfmodal_web-dialog {
    display: inline-block;
}
 */
 
/* FULLSIZE */
.Jfmodal_full {
    display:flex!important;
    justify-self:center!important;
    align-self: center!important;
    align-content: center!important;
    justify-content: center!important;
    width:90%!important;
    max-width: 100vw!important;
    height:max-content!important;
    max-height: max-content!important; /*might need to change back 100vh*/
    min-height: max-content!important;
    padding:0px;
    /* margin:0px; */

}

.Jfmodal_tablet{
    display:flex!important;
    justify-self:center!important;
    align-self: center!important;
    align-content: center!important;
    justify-content: center!important;
    width:90%!important;
    max-width: 100vw!important;
    height:max-content!important;
    max-height: max-content!important; /*might need to change back 100vh*/
    min-height: max-content!important;
    padding:0px;
    /* margin:0px; */
}



/* MOBILE */
.Jfmodal_mobile{
    /* padding:0px;
    margin:-3px;
    text-align: center;
    overflow: hidden; */
    padding:0px;
    margin:0px;
    border-radius: 0px;
}

.Jfmodal_mobile:before {
    content: '';
    overflow-y: hidden;
    align-items: center;
    margin:0px;
}
    
.Jfmodal_mobile-dialog {
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:0px;
  

}

/* .Jfmodal_mobile-.modal-content {
    display: inline-block;
    justify-content: center;
} */



.close{
    outline:none;
    margin-right:10px;
}

.modal-header{
    border-bottom: none;
}

.modal-title{
    font-size: large
}

.ReactModal__Body-open {
    overflow-y: hidden;
}

