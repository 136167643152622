.lrb_root{
    display: flex;
    flex-direction: row;
}
.lrb_left{
    height:32px;
    width: 32px;
    background-color: white;
    border:thin solid #707070;
    border-right:none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: bold;
}
.lrb_right{
    height:32px;
    width: 32px;
    background-color: white;
    border:thin solid #707070;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: bold;
}