.projcard_root{
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #cdcdcd;
    transition:box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.projcard_1del{
    position:absolute;
    display:flex;
    color:red;
    cursor:pointer;
    padding:5px;
    right:0;
}

.projcard_name{
    color:#707070;
    font-weight:bold;
    padding:5px 10px 0px 10px;
    overflow:auto;
}

.projcard_collabroot{
    display:flex;
    flex-direction: row;
    align-items: center;
    height:30px;
    outline:none;
    background-color: white;
    width:100%;
    border:none;
    padding:0px;
}

.projcard_collabfixedtext{
    color:#707070;
}

.projcard_collab{
    display:flex;
    flex-direction: row;
    margin:5px;
    cursor:pointer;
}

.projcard_collabimg{
    width:30px;
    height:30px;
    border-radius: 25px;
    margin:5px;
}

.projcard_collabrow{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
}

.projcard_collabdn{
    display:flex;
    color:#333333;
}

.projcard_collabvt{
    display:flex;
    color:rgb(154, 153, 153);
    font-size: 12px;
}


.projcard_arrowdown{
    margin:4px 0px 0px 10px;
    align-self:center;
    border:solid thick #D9D8D8;
    background-color:transparent;
    height:0px;
    width:0px;
    border-color: transparent;
    border-top-color:#D9D8D8 ;
}

/* 0 */
.projcard_00{
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    /* border-top-left-radius:10px;
    border-top-right-radius:10px; */
    /* border:thin solid white; */
    cursor:pointer;
}
.projcard_01{
    display:flex;
    flex-direction: row;
}
.projcard_02{
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    /* border:thin solid white; */
    cursor:pointer;
}
.projcard_03{
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    /* border:thin solid white; */
    cursor:pointer;
}


/* 1 */
.projcard_10{
    cursor:pointer;
}
.projcard_11{
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.projcard_12{
    /* border-top-left-radius: 10px; */
    /* border: thin solid white; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.projcard_13{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.projcard_14{
    /* border-top-right-radius: 10px; */
    /* border: thin solid white; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.projcard_15{
    /* border:thin solid white; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* 2 */
.projcard_20{
    cursor:pointer;
}
.projcard_21{
    background-position: center;
    background-size: cover;
    /* border-top-left-radius:10px;
    border-top-right-radius:10px; */
}
