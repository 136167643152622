:root {
    --mainpink: #eb5d7b;
    --subpink:#eb5d7b0d;
    --hoverpink: #fff1f4;
    --maindark: #333333;
    --transparentmaindark: #ffffffb5;
    --subdark: #7c7c7c;
    --subgrey: #cbcbcb;
    --lightdark: #e5e5e5;
    --donegreen: #4aac47;
    --firmgreen: #008000;
}
.MainselectionbgWedding{
    top: 0px;
    position: absolute;
    width: 140%;
    height: 130%;
    margin-left: -15%;
    margin-top: -5%;
    opacity: 0.2;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: contain;
    text-decoration: underline #f77f7f12;
}
.MainselectionbgEvent{
    top: 0px;
    position: absolute;
    width: 140%;
    height: 130%;
    margin-left: -20%;
    opacity: 0.2;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: contain;
    text-decoration: underline #f77f7f12;
}
.AppNavItemM{
    min-height: max-content !important;
    color: black !important;
    display: flex !important;
    padding: 15px !important;
    justify-items: flex-start !important;
    letter-spacing: 1px !important;
    font-size: 1.3em;
    place-content: center !important;
    color: #707070 !important;
}
.SELECTED_MSB_vtbtn{
    height:36px;
    width:fit-content;
    padding:8px 16px;
    border-radius:15px;
    white-space: nowrap;
    margin:5px;
    color:white!important;
    border:thin solid transparent!important;
    background-color:#F77F7F!important;
    position:relative;
    align-items: center;
    transition : background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    outline:none;
    display:flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size:13px;
    border: thin solid #707070;
    color: #707070;
    letter-spacing: 1px;
}
.MSB_vtbtn{
    height:36px;
    width:fit-content;
    padding:8px 16px;
    border-radius:15px;
    white-space: nowrap;
    margin:5px;
    color:#707070!important;
    border:thin solid transparent;
    background-color:white!important;
    position:relative;
    align-items: center;
    transition : background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    outline:none;
    display:flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size:13px;
    border: thin solid #707070;
    color: #707070;
    letter-spacing: 1px;
    user-select: none;
}
.MSB_vtbtn:hover{
   background-color: #f77f7f33 !important;
   color:#707070!important;
   height:36px;
   border:thin solid white !important;
   user-select: none;
}
.App_navbaricon{
    width: 40px;
    background-color: transparent;
    color: white;
    box-shadow: none;
    border: none;
    margin-right: 10px;
}
.headertitlelogotext{
    letter-spacing: 0px;
    z-index:1;
    color:#eb5d7b;
    position:absolute;
    filter: blur(20px);
    font-family:'Marck Script';
    font-size: 21px;
}
.headertitlelogorealtextmobile{
    letter-spacing: 0;
    z-index: 1;
    color: white !important;
    font-family: 'Marck Script';
    color: #555555;
    font-size: 22px;
    letter-spacing: 0.5px;
}
.navbar_cereweavetxtroot{
    padding: 0px 5px;
    font-weight:bold;
    text-decoration: none;    
}
.navbar_cereweavetxtroot:hover{
    text-decoration: none;    
}


.app_navroot {
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    display: flex;
    z-index: 1;
    background-color: var(--maindark);
    place-content: space-between;
}

.app_navright {
    display: flex;
    flex-direction: row;
}

.navtopbaritem{
    display: flex;
    align-items: center;
    outline: 0px;
    height: 35px;
    padding: 0px 10px;
    color: white;
    background-color: transparent;
    border: none;
    transition: background-color 1000ms cubic-bezier(0.23, 1, 0.32, 1) 0s, color 1000ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.navtopbaritem:hover{
    background-color: var(--mainpink);
    color: white;
}

textarea:focus, input:focus, input[type]:focus, .uneditable-input:focus {   
    border-color: none;
    box-shadow: none;
    outline: none;
}
