.RSVP_multiplechoice {
    display: flex;
    flex-direction: column;
}

.RSVP_multiplechoiceoption {
    padding: 10px 20px;
    border-radius: 30px;
    height: 40px;
    text-align: start;
    margin-top: 5px;
    align-items: center;
    display: flex;
}

.RSVP_lblinputroot {
    display: flex;
    flex-direction: column;
    margin: 25px 10px 25px 10px;
}

.RSVP_txtarea {
    width: 100%;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    padding: 5px;
    background-color: #ffffff80;
    font-size: 16px;
}

.RSVP_lbl {
    display: flex;
    padding: 0px 10px 0px 0px;
    font-size: 16px;
}

.RSVP_statusrow {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    font-size: 14px;
}

.RSVP_statussent {
    width: 50%;
    height: 40px;
    border-top-left-radius: 5px;
    border-top: 1px solid var(--maindark);
    border-right: none;
    border-bottom: 1px solid var(--maindark);
    border-left: 1px solid var(--maindark);
    border-image: initial;
    border-bottom-left-radius: 0px;
    background-color: #ffffff;
}

.RSVP_statusmid {
    width: 1px;
    height: 100%;
    border-left: 1px solid var(--maindark);
}

.RSVP_statusnotsent {
    width: 50%;
    height: 40px;
    background-color: white;
    border-top: 1px solid var(--maindark);
    border-bottom: 1px solid var(--maindark);
    border-left: none;
    border-right: none;
    border-image: initial;
}

.RSVP_statusreplied {
    width: 50%;
    height: 40px;
    background-color: white;
    border-top-right-radius: 5px;
    border-top: 1px solid var(--maindark);
    border-right: 1px solid var(--maindark);
    border-bottom: 1px solid var(--maindark);
    border-left: none;
    border-image: initial;
    border-bottom-right-radius: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;

}

@media (min-width:320px) {
    .RSVP_statusnotsent {
        font-size: 13px;
    }

    .RSVP_statusreplied {
        font-size: 13px;
    }
}

@media (min-width:720px) {
    .RSVP_statusnotsent {
        font-size: 14px;
    }

    .RSVP_statusreplied {
        font-size: 14px;
    }
}


.RSVP_statusselected {
    background-color: var(--maindark);
    color: white;
}

.ce_row {
    display: flex;
    flex-direction: row;
    place-content: flex-start;
    align-items: center;
    /* padding-bottom: 30px; */
    font-family: Lato;
    margin-top: 5px;
    color: inherit;
}

.ce_twobtn {
    width: max-content;
    height: 30px;
    font-size: 14px;
    background-color: #ffffff;
    color: var(--maindark);
    border: 1px solid var(--maindark);
    padding: 0px 15px;
}

.ce_twobtnselected {
    background-color: var(--mainpink);
    color: white;
}

.ce_twobtnleft {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none;
}

.ce_twobtnright {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
}

.ce_btnsline {
    background-color: var(--maindark);
    height: 100%;
    width: 1px;
}

#ce_inputdescribe {
    color: var(--subdark);
    font-size: 12px;
}

.ce_checkboxroot {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ce_checkbox {
    width: 18px !important;
    height: 18px !important;
}

.ce_checkboxtxt {
    width: max-content;
    padding: 0px 0px 0px 5px;
    user-select: none;
    cursor: pointer;
}