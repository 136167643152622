.partner_root{
    width: 100%;
    border-radius: 5px;
    margin-bottom: 50px;
    color: #707070;
    border:thin solid #d9d8d8;
}
.partner_subroot{
    padding: 40px 5px 5px;
    height: 100%;
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    overflow-x: auto;
}
.partner_modalroot{
    display:flex;
    width:100%;
    height: 150px;
    padding:5px;
    flex-direction: column;
}
.partner_subsubroot{
    grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
}