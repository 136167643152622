.oneimageinput_imginputholder_portrait{
    display:flex;
    width: 150px;
    height: 200px;
    position:relative;
}
.oneimageinput_imginputholder_landscape{
    display:flex;
    width: 230px;
    height: 160px;
    position:relative;
}
.oneimageinput_img_portrait{
    display:flex;
    width: 150px;
    height: 200px;
    position:absolute;
}
.oneimageinput_img_landscape{
    display:flex;
    width: 230px;
    height: 160px;
    position:absolute;
}
.oneimageinput_input{
    opacity:0;
    border:'none';
    width:100%;
    height:100%;
}
