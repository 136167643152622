.Vreviews_root{
    width: 100%;
    border-radius: 5px;
    margin-bottom: 50px;
    color: #707070;
    border: thin solid #d9d8d8;
}
.Vreviews_subroot{
    padding: 40px 10px 5px;
    height: 100%;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -ms-flexbox;
    display: flex;
}