/* 
.filepicker{
    background-color: white !important
} */

.projectcreation_rootWEB{
    width:100%;
    border-radius:5px;
    margin-bottom:50px;
    color:#707070;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.projectcreation_rootTABLET{
    width:100%;
    border-radius:5px;
    margin-bottom:50px;
    color:#707070;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.projectcreation_rootMOBILE{
    width:100%;
    border-radius:5px;
    margin-bottom:50px;
    color:#707070;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.projectcreation_addprojbtn{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}

.projectcreation_addprojbtnmaxlimit{
    display:flex;
    border:dashed thin #D9D8D8;
    background-color:white;
    height:100px;
    width:100%;
    align-items:center;
    justify-content:center;
    text-align:center;
    margin: 5px 0px;
}

.projectcreation_addprojbtncreatenew{
   display:flex;
   border:dashed thin #D9D8D8;
   background-color:white;
   height: 100px;
   width:100%;
   align-items: center;
   justify-content: center;
   text-align: center;
   margin:5px;
   margin-left: 0px;
   margin: 5px 0px;

}

.projectcreation_sidebarWEB{
    display: flex;
    flex-direction: column;
}
.projectcreation_sidebarTABLET{
    display: flex;
    flex-direction: column;
}
.projectcreation_sidebarMOBILE{
    display: flex;
    flex-direction: row;
    padding-bottom:20px;
    justify-content: center;
}

.projectcreation_selectedmenu{
    display: flex;
    padding:2px 5px;
    text-align: center;
    border:thin solid #707070;
    cursor: pointer;
    background-color:#707070;
    color:white;
    place-content: center;
    min-width:100px;
    max-width:100px;
    align-items: center;
}

.projectcreation_menu{
    display: flex;
    padding:2px 5px;
    text-align: center;
    border:thin solid #707070;
    cursor: pointer;
    place-content: center;
    min-width:100px;
    max-width:100px;
    align-items: center;
}

.projectcreation_menu:hover{
    display: flex;
    padding:2px 5px;
    text-align: center;
    border:thin solid #707070;
    cursor: pointer;
    background-color:#efefef;
    color:#707070;
    place-content: center;
    min-width:100px;
    max-width:100px;
    align-items: center;
}