.WEB_root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 390px;
}

.WEB_subroot {
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);
    border: thin solid rgb(217, 216, 216);
    border-radius: 5px;
    overflow-y: auto;
    height: 390px;
}

.WEB_item {
    display: flex;
    flex-direction: column;
    height: max-content;
    width: 100%;
    overflow-y: auto;
    height: 390px;
}

.TABLET_root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 390px;
}

.TABLET_subroot {
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);
    border: thin solid rgb(217, 216, 216);
    border-radius: 5px;
    overflow-y: auto;
    height: 390px;
}

.TABLET_item {
    display: flex;
    flex-direction: column;
    height: max-content;
    width: 100%;
    overflow-y: auto;
    height: 390px;

}

.MOBILE_root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MOBILE_subroot {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: thin solid rgb(217, 216, 216);
    border-radius: 5px;
    height: 460px;
    margin-top: 20px;
}

.MOBILE_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    overflow-y: auto;
    height: 100%;
}

.news_root {
    border: thin solid rgb(217, 216, 216);
    width: 100%;
    height: max-content;
    justify-content: 'center';
    border-radius: 5px;
    margin-bottom: 20px;
}

.statistics_root {
    border: thin solid rgb(217, 216, 216);
    height: 670px;
    justify-content: flex-start;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}

.statistics_subroot {
    justify-content: center;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}

.statistics_chartroot {
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    width: 100%;
    align-items: center;
}

.hv_title_root {
    font-size: large;
    font-weight: bold;
    padding: 10px;
    height: 50px;
    width: max-content;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.hv_singletxtstrip {
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin: 3px 0px;
    border-radius: 5px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.hv_modaltitle {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    justify-content: flex-start;
    font-size: 30px;
}

.hv_showallbtn {
    padding: 0px 10px;
    border-radius: 10px;
    font-weight: bold;
    border: thin solid #707070;
    background-color: #707070;
    color: white;
    outline: none;
    font-size: 12px;
    border-radius: 5px
}

.hv_texttitle {
    font-weight: bold;
}

.hv_chatbtn {
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid #707070;
    background-color: white;
    outline: none;
}

.hv_periodrootWEB {
    display: flex;
    flex-direction: column;
    place-content: center flex-start;
    width: 33.33%;
    height: 100px;
    margin: 10px;
    border: thin solid #aeaeae;
    border-radius: 5px;
    background-color: white;
}

.hv_periodrootTABLET {
    display: flex;
    flex-direction: column;
    place-content: center flex-start;
    width: 33.33%;
    height: 100px;
    margin: 10px;
    border: thin solid #aeaeae;
    border-radius: 5px;
    background-color: white;

}

.hv_periodrootMOBILE {
    display: flex;
    flex-direction: column;
    place-content: center flex-start;
    width: 33.33%;
    height: 100px;
    margin: 10px;
    border: thin solid #aeaeae;
    border-radius: 5px;
    background-color: white;

}

.hv_periodtitle {
    margin: 15px 5px 0px;
    display: flex;
    justify-content: center;
}

.hv_periodnum {
    display: flex;
    justify-content: center;
}

.hv_statsroot {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-top: thin dashed #d9d8d8;
    margin-top: 20px;
}

.hv_charticonroot {
    display: flex;
    flex-direction: row;
}

.hv_icon {
    outline: none;
    color: #707070;
    text-decoration: none;
    width: 30px;
    height: 30px;
    border: thin solid #aeaeae !important;
    display: flex;
    align-items: center;
    place-content: center;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px;
}

.hv_btnroot {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.hv_ntenuff {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.hv_modalcontentWEB {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.hv_modalcontentTABLET {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.hv_modalcontentMOBILE {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 100vw;
}

.hv_modalsinglestrip {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin: 3px 0px;
    border-radius: 5px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border: thin solid rgb(217, 216, 216);
}

.hv_modalsingleleftroot {
    white-space: nowrap;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.hv_modalsinglelefttop {
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
}

.hv_modalsingleleftbtm {
    padding: 0px 5px;
    align-items: center;
    text-align: center;
    display: flex;
    font-size: 13px;
    color: #bababa;
}

.hv_modalsingleleftbtmun {
    font-size: 13px;
    color: #bababa;
}

.hv_rightresetbtn {
    display: flex;
    margin: 0px 10px;
    width: max-content;
    background-color: white;
    color: var(--maindark);
    border-radius: 5px;
    border: thin solid #aeaeae;
    place-self: flex-end;
    font-weight: bold;
}

.hv_newsupdatestrip {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 5px;
    padding-bottom: 10px;
}

.hv_newsupdatedate {
    padding: 5px;
}

.hv_newsupdatecontent {
    padding: 5px;
    overflow: auto;
}

.hv_newsupdateexpand {
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
    place-content: center;
    align-items: center;
}

.hv_newsupdateroot {
    width: 100%;
    display: flex;
    flex-direction: column;
}