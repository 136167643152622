.Mod_root{
    width: 100vw;
    height: 100vh;
    background-color: #00000080;
    position: fixed;
    top: 0px;
    left:0px;
    z-index: 1002;
}
.Mod_subroot{
    width: 200px;
    height: max-content;
    padding: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: white;
    border: thin solid black;
    flex-direction: column;
    place-content: center;
    place-content: center;
    align-items: center;
    left:calc( 50% - 100px );
}
.Mod_text{
    display: flex;
    text-align: center;
    padding: 5px;
}
.Mod_row{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 10px;
}
.Mod_ok{
    width: 50%;
    display: flex;
    place-content: center;
    font-weight: bold;
    cursor: pointer;
    color: #5cb650;
}
.Mod_no{
    width: 50%;
    display: flex;
    place-content: center;
    font-weight: bold;
    cursor: pointer;
    color: red;
}