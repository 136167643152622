.packagemodal{
    display: flex;
    justify-content: center;
}
.pricelistcreation_rootWEB{
    width:100%;
    border-radius:5px;
    margin-bottom:50px;
    color:#707070;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pricelistcreation_rootTABLET{
    width:100%;
    border-radius:5px;
    margin-bottom:50px;
    color:#707070;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pricelistcreation_rootMOBILE{
    width:100%;
    border-radius:5px;
    margin-bottom:50px;
    color:#707070;
    display: flex;
    flex-direction: column;
    align-items: center;
}