.topmenu_rootWEB{
    display:flex;
    flex-direction: row;
    place-content: center;
    padding-bottom: 20px;
}
.topmenu_rootTABLET{
    display:flex;
    flex-direction: row;
    place-content: flex-start;
    padding-bottom: 20px;
}
.topmenu_rootMOBILE{
    display:flex;
    flex-direction: row;
    place-content: center;
    padding-bottom: 20px;
}
.topmenutrue{
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    background-color:#eb5d7b;
    border:none;
    outline: none;
    margin: 10px 5px;
    width: 75px;
    height: 65px;
    display:flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    cursor:pointer;
    align-items: center;
    text-align: center;
}
.topmenufalse{
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid #aeaeae;
    background-color: white;
    outline: none;
    color: #707070;
    margin: 10px 5px;
    width: 75px;
    height: 65px;
    display:flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    cursor:pointer;
    text-align: center;
}