.line {
    fill: none;
    stroke: #ffab00;
    stroke-width: 3;
}
  
.overlay {
  fill: none;
  pointer-events: all;
}

/* Style the dots by assigning a fill and stroke */
.dot {
    fill: #ffab00;
    stroke: #fff;
}
  
.focus circle {
  fill: none;
  stroke: steelblue;
}

.grid{
    color: #f4dddd
}


div.tooltip {	
    position: absolute;		
    display:flex;
    flex-direction: row;	
    text-align: center;			
    width: max-content;					
    height: max-content;					
    padding: 5px;				
    font: 12px sans-serif;		
    background: rgb(99, 99, 99);	
    border: 0px;		
    border-radius: 8px;			
    pointer-events: none;	
}



svg {
  font: 10px sans-serif;
}


.bar {
  fill: steelblue;
  clip-path: url(#clip);
}
  
.subBar { 
  fill: gray;
  opacity: 0.5;
}

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.brush .extent {
  stroke: #fff;
  fill: steelblue;
  fill-opacity: .25;
  shape-rendering: crispEdges;
}

/* .tick line{
  visibility:hidden;
} */